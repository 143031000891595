import React from "react";
import Layout from "../components/layout/layout";
import SourceDestinationSelector from "../components/misc/source-destination-selector";

const NotFoundPage = ({ data, pageContext }) => {
    const menus = pageContext.menus?.items;

    return (
        <Layout
            menus={menus}
            headerType={`404`}
            pageHeading={"Well, this is embarrassing."}
            pageSubHeading={
                "Looks like we skipped a stitch. Not to worry, you can head home and start from the beginning, or click any of the navigation items at the top of this page."
            }
        >
            <SourceDestinationSelector heading={`Pick your source and your destination`} position={1} />
        </Layout>
    );
};

export default NotFoundPage;
